<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="$t('champion')"/>
    </div>

    <div class="container py-5">
			<div class="">

				<div class="form-group">
					<select
						class="form-control"
						style="
							font-size: 0.75rem;
							height: 2.5rem;
							width: auto;
							background-position: right 0.75rem center;
						"
						v-model="selected_event">
						<option value="">Choose Event</option>
						<option :value="event.id" v-for="(event, index) in arr_event" :key="index">{{ event.name }}</option>
					</select>
				</div>

				<div>
					<table class="table table-member table-striped d-block d-md-table">
						<tbody>
							<tr v-for="(event_category_sport, index) in arr_event_category_sport" :key="index">
								<td>{{ event_category_sport.event.name }}</td>
								<td>{{ event_category_sport.category_sport.name }}</td>
								<td>
									<a href="#!" @click="onChampClicked(index)">
										<div class="d-flex align-items-center">
											<font-awesome-icon :icon="['fas', 'download']" class="text-primary"/>
											<p class="m-0 ml-1">Champ</p>
										</div>
									</a>
								</td>
								<td>
									<a href="#!" @click="onStatClicked(index)">
										<div class="d-flex align-items-center">
											<font-awesome-icon :icon="['fas', 'download']" class="text-primary"/>
											<p class="m-0 ml-1">Participant</p>
										</div>
									</a>
								</td>
								<td>
									<a :href="'/competition/information?id=' + event_category_sport.id" target="_blank">
										<div class="d-flex align-items-center">
											<font-awesome-icon :icon="['fas', 'eye']" class="text-primary"/>
											<p class="m-0 ml-1">Detail</p>
										</div>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

			</div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import NavbarDetail from '@/layout/navbar_detail.vue'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
  },
  data(){
    return{
      base: null,
      scrollY: 0,
      arr_factor: [false, ],
      flag: {
        aboutUsTitle1Flag: false,
        aboutUsImage1Flag: false,
        aboutUsContent1Flag: false,
        aboutUsTitle2Flag: false,
        aboutUsImage2Flag: false,
        aboutUsContent2Flag: false,
        aboutUsTitle3Flag: false,
        aboutUsImage3Flag: false,
        aboutUsContent3Flag: false,
      },
			selected_event: '',
			arr_event_category_sport: [],
			arr_event: [],
    }
  },
  watch: {
    arr_factor(val){
      this.$emit('onChangeArrFactor', val)
    },
    scrollY(){
    },
		selected_event(){
			this.get_event_category_sport()
		}
  },
  created(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1
    this.arr_factor = [true, ]

		this.get_event()
		this.get_event_category_sport()
  },
  methods: {
		async get_event() {
			var response = await this.base.request(this.base.url_api + "/event/all?type=past");

			if (response != null) {
				if (response.status === "success") {
					this.arr_event = response.data;
				}
				else
					this.base.show_error(response.message);
			}
			else
				console.log(this.$t("server_error"));
		},
		async get_event_category_sport() {
			var response = await this.base.request(this.base.url_api + "/event/category-sport" + (this.selected_event != '' ? '/all' : '') + "?type=past&event_id=" + this.selected_event);

			if (response != null) {
				if (response.status === "success") {
					this.arr_event_category_sport = response.data;
				}
				else
					this.base.show_error(response.message);
			}
			else
				console.log(this.$t("server_error"));
		},
    onChampClicked(index){
			var data = this.arr_event_category_sport[index]
      window.open(this.base.host + '/export/award-player?event_category_sport_id=' + data.id, '_blank')
    },
		onStatClicked(index){
			var data = this.arr_event_category_sport[index]
			window.open(this.base.host + '/export/recap-player?event_category_sport_id=' + data.id, '_blank')
		},
  }
}
</script>

<style lang="scss">
.custom-title{
  color: $primary;
  font-family: poppins-bold;
}
.about-us-brand{
  font-family: montserrat-bold;
  font-size: 1rem;
}
.about-us-medal-detail{
  color: $gray20;
}
.about-us-description{
  font-family: montserrat-bold;
  font-size: 1rem;
}
.trust-card{
  background-color: $gray9;
  border: none;
}
.title-section{
  font-size: 2rem;
  font-family: poppins-medium;
}
.content-section{
  color: $gray6;
}
.team-role{
  color: $black1;
}
.team-name{
  color: $black1;
  font-family: poppins-bold;
}
.about-us-title1-enter-active, .about-us-title1-leave-active,
.about-us-title2-enter-active, .about-us-title2-leave-active,
.about-us-title3-enter-active, .about-us-title3-leave-active{
  transition: all 2s;
}
.about-us-title1-leave-to, .about-us-title1-enter,
.about-us-title2-leave-to, .about-us-title2-enter,
.about-us-title3-leave-to, .about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}
.about-us-content1-enter-active, .about-us-content1-leave-active,
.about-us-content2-enter-active, .about-us-content2-leave-active,
.about-us-content3-enter-active, .about-us-content3-leave-active{
  transition: all 2s;
}
.about-us-content1-leave-to, .about-us-content1-enter,
.about-us-content2-leave-to, .about-us-content2-enter,
.about-us-content3-leave-to, .about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}
.about-us-image1-enter-active, .about-us-image1-leave-active,
.about-us-image2-enter-active, .about-us-image2-leave-active,
.about-us-image3-enter-active, .about-us-image3-leave-active{
  transition: all 2s;
}
.about-us-image1-leave-to, .about-us-image1-enter,
.about-us-image2-leave-to, .about-us-image2-enter,
.about-us-image3-leave-to, .about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}
#about-us-content img{
  width: 100%;
  border-radius: 1rem;
}
.trust-list > p:nth-child(1){
  min-height: 100px;
  text-align: center;
}
.trust-list > p:nth-child(2){
  text-align: justify;
  text-align-last: center;
}
.p-spacing{
  text-align: justify;
  word-spacing: 0.1rem;
  line-height: 200%;
}
.gray-777{
  color: $gray20;
}
</style>
