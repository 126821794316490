<template>
  <div ref="footer" class="bg-primary">
    <div class="container">
      <div class="row py-3 text-warning">
        <div class="col-12 col-md-6">
          <div class="text-center text-md-left footer-content">
            © 2025 Student Open Copyright and rights reserved
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="text-center text-md-right footer-content">
            <a href="#" class="text-warning">{{ $t('term_condition') }}</a>
            <a href="#" class="text-warning ml-3">{{ $t('privacy_policy') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      scrollY: 0,
      arr_factor: [false, ],
      address: '',
      phone: '',
      call_only: 'Call Only: 082211776223',
      wa_only: 'WA Only: 081283228292',
    }
  },
  created(){
    this.base = new Base()
    window.addEventListener('scroll', this.handleScroll)
    this.scrollY = 1

    this.get_setting()
  },
  methods: {
    onClickPhone(){
      location.href = 'tel:' + this.phone
    },
    async get_setting(){
      var response = await this.base.request(this.base.url_api + "/setting")
      this.$set(this.arr_factor, 1, true)

      if(response != null){
        if(response.status === "success"){
          for(let setting of response.data){
            if(setting.key === "address")
              this.address = setting.value
            else if(setting.key === "phone")
              this.phone = setting.value
            else if(setting.key === "wa_only")
              this.wa_only = "WA Only: "+setting.value
            else if(setting.key === "call_only")
              this.call_only = "Call Only: "+setting.value
          }
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 960px) {
  .footer-content-card{
    margin-top: 7rem;
    text-align: center;
  }
  .footer-download-image{
    margin: 0;
  }
  .footer-download-image1{
    width: 100%;
  }
  .footer-container{
    background-color: $gray2;
    margin-top: -5rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1517px) {
  .footer-content-card{
    margin-top: 12rem;
    text-align: center;
  }
  .footer-download-image{
    text-align: center;
  }
  .footer-download-image1{
    width: $content-width;
  }
  .footer-container{
    background-color: $gray2;
    margin-top: -10rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media only screen and (min-width: 1517px) {
  .footer-content-card{
    margin-top: 12rem;
    text-align: center;
  }
  .footer-download-image{
    text-align: center;
  }
  .footer-download-image1{
    width: $content-width;
  }
  .footer-container{
    background-color: $gray2;
    margin-top: -10rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}



.horizontal-footer{
  background-color: $gray3;
  height: 1px;
}
.phone-number{
  font-family: poppins-bold;
  font-size: .8rem;
}
.footer-link{
  text-decoration: none;
  font-size: .8rem;
  color: $black;
}
.footer-title{
  font-family: poppins-medium;
  font-size: 1rem;
  color: $black;
}
.footer-content{
  font-size: .8rem;
}
</style>